<script>
import moment from 'moment'
import api from '@/command/api'
import apiTool from '@/command/apiTool'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import DrawerFormModal from '../../../components/DrawerFormModal'
import { getAction } from '@/command/netTool'

export default {
  name: 'yunXian',
  data() {
    return {
      ...api.command.getState(),
    }
  },
  mounted() {
    this.initList()
  },
  methods: {
    initList() {
      api.command.getList.call(this, {
        url: '/farmCelebrityCountrySquire/page',
        current: 1,
        paramsValue: {},
      })
    },
    getHeader() {
      return [
        {
          name: '名称',
          key: 'name',
          type: 'input',
        },
        {
          label: '',
          type: 'text',
        },
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id,
        },
        {
          dataIndex: 'mapIcon',
          title: '图片',
          align: 'left',
          customRender: (text, records) => {
            const src = records.mapIcon
            return (
              <div class="product-order-item-copy">
                <img src={src} />
              </div>
            )
          },
        },
        {
          dataIndex: 'name',
          title: '名称',
          align: 'left',
          sorter: (a, b) => a.name.length - b.name.length,
        },
        {
          dataIndex: 'lon',
          title: '位置',
          align: 'left',
          customRender: (text, records) => {
            return `经度：${records.lon || '未知'} 纬度：${records.lat || '未知'}`
          },
          onExport: (text, records) => {
            return `经度：${records.lon || '未知'} 纬度：${records.lat || '未知'}`
          },
        },
        {
          dataIndex: 'upDown',
          title: '状态',
          type: 'badge',
          onExport: (records) => {
            return ['下架', '上架'][records]
          },
          filterMultiple: false,
          render(data) {
            console.log(data, 'data')
            return {
              showDot: true,
              name: data == 0 ? '上架' : '下架',
              color: data == 0 ? 'green' : 'red',
            }
          },
        },
        {
          dataIndex: 'a8',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          typeData: ({ records }) => {
            return [
              {
                name: records.upDown == 0 ? '下架' : '上架',
                display: true,
                onClick: () => {
                  if (records.upDown == 0) {
                    api.command.editGET.call(this, {
                      url: `/farmCelebrityCountrySquire/down?id=${records.id}`,
                    })
                  } else {
                    api.command.editGET.call(this, {
                      url: `/farmCelebrityCountrySquire/up?id=${records.id}`,
                    })
                  }
                },
              },
              {
                name: '编辑',
                onClick: () => {
                  this.$router.push('/contentManagement/yunXianDetail?id=' + records.id)
                },
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认删除？',
                onClick: () =>
                  api.command.del.call(this, {
                    url: `/farmCelebrityCountrySquire`,
                    params: {
                      id: records.id,
                    },
                  }),
              },
            ]
          },
        },
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => {
            this.$router.push('/contentManagement/yunXianDetail')
          },
        },
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.del.call(this, {
                  url: `/farmCelebrityCountrySquire/deleteBatch`,
                  params: { idList },
                })
              },
            },
            {
              name: '批量上架',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.edit.call(this, {
                  url: `/farmCelebrityCountrySquire/upBatch`,
                  params: { idList },
                })
              },
            },
            {
              name: '批量下架',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.edit.call(this, {
                  url: `/farmCelebrityCountrySquire/downBatch`,
                  params: { idList },
                })
              },
            },
          ],
        },
      ]
    },
  },
  render() {
    return (
      <div>
        <TemplateHeaderTable
          headerData={this.getHeader()}
          tableColumns={this.getColumns()}
          button={this.getButton()}
          records={this.records}
        />
      </div>
    )
  },
}
</script>
